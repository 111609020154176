import { Routes } from '@angular/router';
import { authGuard, cookieGuard, UserRole } from '@ess-front/shared';
import { DialogType } from '@util/models/dialog-type.model';

const routes: Routes = [
  {
    path: 'trip-builder/:tripId',
    loadComponent: () =>
      import('./app/layout/trip-builder/trip-builder.component').then(mod => mod.TripBuilderComponent),
    canActivate: [cookieGuard, authGuard(UserRole.StaffUser)],
    children: [
      { path: '', redirectTo: 'itinerary', pathMatch: 'full' },
      {
        path: 'itinerary',
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./app/itinerary/ui/itinerary/itinerary.component').then(mod => mod.ItineraryComponent),
            children: [
              {
                path: 'experience/:experienceId',
                children: [
                  {
                    path: 'editor',
                    loadComponent: () =>
                      import('./app/itinerary/ui/dialog-handler/dialog-handler.component').then(
                        mod => mod.DialogHandlerComponent,
                      ),
                    data: { dialogType: DialogType.Editor },
                  },
                  {
                    path: 'availability',
                    loadComponent: () =>
                      import('@itinerary/ui/dialog-handler/dialog-handler.component').then(
                        mod => mod.DialogHandlerComponent,
                      ),
                    data: { dialogType: DialogType.Availability },
                  },
                  {
                    path: 'checkout',
                    loadComponent: () =>
                      import('@itinerary/ui/dialog-handler/dialog-handler.component').then(
                        mod => mod.DialogHandlerComponent,
                      ),
                    data: { dialogType: DialogType.Checkout },
                  },
                ],
              },
              {
                path: 'day/:dayId/map',
                loadComponent: () =>
                  import('@itinerary/ui/dialog-handler/dialog-handler.component').then(
                    mod => mod.DialogHandlerComponent,
                  ),
                data: { dialogType: DialogType.Map },
              },
              {
                path: 'new-experience',
                loadComponent: () =>
                  import('@itinerary/ui/dialog-handler/dialog-handler.component').then(
                    mod => mod.DialogHandlerComponent,
                  ),
                data: { dialogType: DialogType.NewExperience },
              },
              {
                path: 'google-places-import',
                loadComponent: () =>
                  import('@itinerary/ui/dialog-handler/dialog-handler.component').then(
                    mod => mod.DialogHandlerComponent,
                  ),
                data: { dialogType: DialogType.GooglePlacesImport },
              },
            ],
          },
          {
            path: 'experience/:experienceId/flights',
            loadComponent: () =>
              import('./app/flights/ui/flights-view/flights-view.component').then(mod => mod.FlightsViewComponent),
          },
          {
            path: 'day/:dayId/alternative-days',
            loadComponent: () =>
              import('./app/alternative-days/ui/alternative-days/alternative-days.component').then(
                mod => mod.AlternativeDaysComponent,
              ),
          },
        ],
      },
      {
        path: 'curation',
        loadComponent: () => import('./app/curation/ui/curation/curation.component').then(mod => mod.CurationComponent),
      },
      {
        path: 'invoices',
        loadComponent: () =>
          import('./app/invoicing/ui/invoices/invoices.component').then(mod => mod.InvoicesComponent),
        children: [
          {
            path: 'experience/:experienceId/editor',
            loadComponent: () =>
              import('./app/itinerary/ui/dialog-handler/dialog-handler.component').then(
                mod => mod.DialogHandlerComponent,
              ),
            data: { dialogType: DialogType.Editor },
          },
        ],
      },
    ],
  },
  { path: 'login', loadComponent: () => import('./app/layout/login/login.component').then(mod => mod.LoginComponent) },
  {
    path: '',
    loadComponent: () => import('./app/layout/home/home.component').then(mod => mod.HomeComponent),
  },
  {
    path: '**',
    loadComponent: () => import('./app/layout/not-found/not-found.component').then(mod => mod.NotFoundComponent),
  },
];

export default routes;
