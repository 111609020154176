import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { NOTIFICATIONS } from '@util/constants/notifications';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessagesService {
  getServerErrorMessage({ status, error }: HttpErrorResponse): string {
    const errorMessage = error?.detail;
    if (errorMessage) {
      return errorMessage;
    }
    switch (status) {
      case HttpStatusCode.BadRequest:
        return error ? this.getBadRequestErrorMessage(error) : NOTIFICATIONS.Errors.Default;
      case HttpStatusCode.Unauthorized:
        return NOTIFICATIONS.Errors.Unauthorized;
      case HttpStatusCode.Forbidden:
        return NOTIFICATIONS.Errors.Forbidden;
      default:
        return NOTIFICATIONS.Errors.Default;
    }
  }

  private getBadRequestErrorMessage(error: Record<string, string[]>): string {
    if (error['__all__'] || error['non_field_errors'] || Array.isArray(error)) {
      // "__all__ or non_field_errors" error mean something went wrong in the form with no specific field
      const errorDetails = error['__all__'] || error['non_field_errors'] || error;
      return `${errorDetails.join(' ')}`;
    } else if (error['detail']) {
      // If api responds with a generic error with a custom message
      return error['detail'].toString();
    } else if (Object.keys(error).length) {
      // "__all__ or non_field_errors" error mean something went wrong in the form with no specific field
      return Object.entries(error).reduce((result: string, [key, value], i) => {
        return i === 0 ? `${key}: ${value.join(' ')}` : `${result}\n${key}: ${value.join(' ')}`;
      }, '');
    }
    return NOTIFICATIONS.Errors.Default;
  }
}
