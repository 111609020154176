import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { release } from '@release';

@Component({
  selector: 'engine-root',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private matIconRegistry: MatIconRegistry) {
    /* eslint-disable-next-line no-console */
    console.log(` ℹ️ Release: %c${release}`, 'color: blue');
    this.matIconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  }
}
