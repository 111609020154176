import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Router } from '@angular/router';
import { ErrorMessagesService } from './error-messages.service';
import { ErrorLoggerService } from './error-logger.service';
import { NOTIFICATIONS } from '@util/constants/notifications';
import { NotificationService } from '@shared/domain/notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private ngZone: NgZone,
    private errorLoggerService: ErrorLoggerService,
    private errorMessagesService: ErrorMessagesService,
    private router: Router,
    private notificationService: NotificationService,
  ) {}

  handleError(error: Error | HttpErrorResponse): void {
    if (error instanceof HttpErrorResponse) {
      this.handleHttpError(error);
      return;
    }
    this.handleClientError(error);
  }

  private handleHttpError(error: HttpErrorResponse): void {
    if (error.status === HttpStatusCode.Unauthorized) {
      this.handleNonAuthError(error);
      return;
    }
    const message = this.errorMessagesService.getServerErrorMessage(error);
    this.errorLoggerService.logError(error, message);
    this.ngZone.run(() => {
      this.notificationService.showError(message, 8000);
    });
  }

  private handleNonAuthError(error: HttpErrorResponse): void {
    if (!this.router.url.includes('login')) {
      const message = this.errorMessagesService.getServerErrorMessage(error);
      this.ngZone.run(() => {
        this.notificationService.showError(message, 8000);
        this.router.navigate(['login'], { state: { redirectUrl: this.router.url } });
      });
    }
  }

  private handleClientError(error: Error): void {
    this.errorLoggerService.logError(error);
    this.ngZone.run(() => {
      this.notificationService.showError(error.message ?? NOTIFICATIONS.Unexpected, 8000);
    });
  }
}
